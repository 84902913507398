import * as Yup from 'yup';
import { YupId } from '../form/schema';
import { checkLegalGapDays } from '../nurseDeployments/helpers';

export const nurseSuggestionSchema = Yup.object().shape({
    nurseRequestId: YupId(),
    careFrom: Yup.date().nullable().required('Pflichtfeld'),
    careTill: Yup.date().nullable().min(Yup.ref('careFrom'), 'Darf nicht vor Beginn liegen'),

    nurseId: YupId().nullable().required('Pflichtfeld'),

    priceAgency: Yup.number().required('Pflichtfeld'),
    priceAgencyDaily: Yup.boolean().required('Pflichtfeld'),
    surchargeAgency: Yup.number().required('Pflichtfeld'),
    provisionPZH: Yup.number()
        .required('Pflichtfeld')
        .max(Yup.ref('priceAgency'), 'Darf nicht Preis Agentur übersteigen'),
    surchargePZH: Yup.number()
        .required('Pflichtfeld')
        .max(Yup.ref('provisionPZH'), 'Darf nicht Provision PZH übersteigen'),
    provisionLocation: Yup.number()
        .required('Pflichtfeld')
        .max(Yup.ref('provisionPZH'), 'Darf nicht Provision PZH übersteigen'),

    presentedByAgencyAt: Yup.date()
        .nullable()
        .max(Yup.ref('careFrom'), 'Darf nicht nach Beginn liegen'),
    interviewedAt: Yup.date().nullable(),
    interviewedBy: Yup.string(),
    interviewedByLocation: Yup.string(),
    presentedAt: Yup.date()
        .nullable()
        .min(Yup.ref('interviewedAt'), 'Darf nicht vor Interview liegen')
        .max(Yup.ref('careFrom'), 'Darf nicht nach Beginn liegen'),
    confirmedAt: Yup.date().nullable().max(Yup.ref('careFrom'), 'Darf nicht nach Beginn liegen'),
    agencyConfirmedAt: Yup.date().nullable(),
    rejectedAt: Yup.date().nullable(),
    rejectedBy: Yup.string(),
    internalRejectionReason: Yup.string(),
    internalRejectionNote: Yup.string(),
    notes: Yup.string(),
});

export const acceptNurseSuggestionSchema = (nursedeployments) => {
    return Yup.object().shape({
        careFrom: Yup.date().nullable()
        .test(
            'is-date-in-use',
            function (value) {
                const { careFrom } = this.parent;
                const result = checkLegalGapDays(nursedeployments, null, careFrom, 'nurse');
                if (!result.isValid) {
                    return this.createError({ message: result.message });
                }
                return true;
            }
        ),

        careTill: Yup.date().nullable().min(Yup.ref('careFrom'), 'Darf nicht vor Beginn liegen'),
        confirmedAt: Yup.mixed().required('Pflichtfeld'),
        agencyConfirmedAt: Yup.mixed(),
        customerContractId: YupId().required('Pflichtfeld'),
    })
};

export const rejectNurseSuggestionSchema = Yup.object().shape({
    rejectedAt: Yup.mixed().required('Pflichtfeld'),
    rejectedBy: Yup.string(),
    internalRejectionReason: Yup.string(),
});
