// Note: Export JSON to CSV
import {
  RADIO_NO_YES,
  SELECT_MARITAL_STATUS,
  RADIO_DEMENTIA_LISTENS_TO,
  RADIO_DEMENTIA_RESTRICTIONS,
  SELECT_SALUTATION,
  SELECT_DEMENTIA_TYPES,
  RADIO_YES_NO_UNCLEAR,
  CHECK_MUSCULOSKELETAL,
  CHECK_NEUROLOGICAL,
  CHECK_CARDIOVASCULAR,
  CHECK_GASTRODUODENAL,
  CHECK_RESPIRATORY,
  CHECK_CURRENT_PROBLEMS,
  CHECK_OTHER_ILLNESSES,
  CHECK_REST_AND_SLEEP,
  RADIO_SLEEP_INTERRUPTIONS,
  RADIO_HELP_NEEDED,
  RADIO_TRANSFER,
  RADIO_DEPENDENCY_LEVELS_EXTENDED,
  CHECK_FOOD_DIET,
  RADIO_FOOD_NUTRITIONAL_STATUS,
  RADIO_FOOD_FLUID_INTAKE,
  RADIO_EXCRETION,
  CHECK_EXCRETION_OTHER,
  RADIO_NO_SOMETIMES_ALWAYS,
  RADIO_INCONTINENCE_CARE,
  CHECK_BREATHING_IMPEDIMENTS,
  RADIO_SAFETY_CONSCIOUSNESS,
  RADIO_SAFETY_MEDICATION_INTAKE,
  RADIO_NONE_SOMETIMES_ALWAYS,
  RADIO_DEPENDENCY_LEVELS,
  RADIO_YES_NO,
  CHECK_CURRENT_THERAPIES,
  RADIO_LANGUAGES,
  CHECK_SPEECH_IMPEDIMENTS,
  CHECK_HEARING_IMPEDIMENTS,
  CHECK_VISUAL_IMPEDIMENTS,
  CHECK_MENTAL_STATE,
  RADIO_ZERO_TO_TEN,
  CHECK_AIDS_IN_HOUSEHOLD,
  CHECK_HOUSEHOLD_TASKS,
  RADIO_NO_OF_PEOPLE,
  CHECK_HOUSEHOLD_WISHES,
  CHECK_HOUSEHOLD_CLEANING,
  RADIO_RESIDENTIAL_SITUATION,
  NURSE_ACCOMODATION,
  RADIO_GENDER,
  CHECK_LANGUAGE_SKILL,
  CHECK_AGE,
  CHECK_QUALIFICATION,
  RADIO_DENOMINATION,
} from "../labelValues";

import { getAge } from "../../../helpers/dates";
import { formatDate } from "../../../helpers/tools";

export const findElementLabel = (label, elements) => {
  //console.log("findElementLabel", label);
  const element = elements.find((element) => element.value === label);
  return element ? element.label : "-";
}

export const ExportLatestQuestionaireDataJsonToCsv = (patient, questionnaire, maincontact, delimiter) => {

  //console.log("ExportLatestQuestionaireDataJsonToCsv",patient, questionnaire, maincontact, delimiter);

  if (delimiter == null || delimiter === "" || delimiter.length > 1) {
    delimiter = ";";
  }

  if (patient == null || patient === undefined) {
    throw new Error(
      "Invalid or empty patient array passed to ExportLatestQuestionaireDataJsonToCsv(patient, questionnaire, maincontact, delimiter)."
    );
  }
  if (questionnaire == null || questionnaire === undefined) {
    throw new Error(
      "Invalid or empty questionnaire array passed to ExportLatestQuestionaireDataJsonToCsv(patient, questionnaire, maincontact, delimiter)."
    );
  }
  if (maincontact == null || maincontact === undefined) {
    throw new Error(
      "Invalid or empty maincontact array passed to ExportLatestQuestionaireDataJsonToCsv(patient, questionnaire, maincontact, delimiter)."
    );
  }
  const resultDictionary = {};

  //
  // MainCustomerContactPage
  //
  // Kontaktdaten Ansprechpartner für Pflege zu Hause Küffel
  resultDictionary[
    "Kontaktdaten Ansprechpartner für Pflege zu Hause Küffel - Postleitzahl"
  ] = maincontact?.zip;
  resultDictionary[
    "Kontaktdaten Ansprechpartner für Pflege zu Hause Küffel - Stadt"
  ] = maincontact?.city;

  //
  // PatientContactPage
  //
  // Kontaktdaten der zu betreuenden Person
  resultDictionary["Kontaktdaten der zu betreuenden Person - Geschlecht"] =
    findElementLabel(patient.salutation, SELECT_SALUTATION);
  resultDictionary["Kontaktdaten der zu betreuenden Person - Stadt"] =
    patient?.city;
  resultDictionary["Kontaktdaten der zu betreuenden Person - Postleitzahl"] =
    patient?.zip;
  resultDictionary["Kontaktdaten der zu betreuenden Person - Geburtsdatum"] =
    formatDate(patient?.bornAt);
  resultDictionary["Kontaktdaten der zu betreuenden Person - Alter"] = getAge(
    patient?.bornAt
  );
  resultDictionary["Kontaktdaten der zu betreuenden Person - Größe (in cm)"] =
    questionnaire.height;
  resultDictionary["Kontaktdaten der zu betreuenden Person - Gewicht (in kg)"] =
    questionnaire.weight;
  resultDictionary["Kontaktdaten der zu betreuenden Person - Raucher/in"] =
    findElementLabel(questionnaire.questionnaireData.smoker, RADIO_NO_YES);

  resultDictionary["Kontaktdaten der zu betreuenden Person - Familienstand"] =
    findElementLabel(
      questionnaire.questionnaireData.maritalStatus, SELECT_MARITAL_STATUS
    );

  resultDictionary["Kontaktdaten der zu betreuenden Person - Konfession"] =
    findElementLabel(
      questionnaire.questionnaireData.denomination.value,
      RADIO_DENOMINATION
    );
  resultDictionary["Kontaktdaten der zu betreuenden Person - Pflegegrad"] =
    questionnaire.nursingLevel;

    resultDictionary[
      "Kontaktdaten der zu betreuenden Person - Höherstufung beantragt?"
    ] = findElementLabel(questionnaire.upgradeRequested, RADIO_NO_YES);

  resultDictionary[
    "Kontaktdaten der zu betreuenden Person - Vorsorgevollmacht vorhanden?"
  ] = findElementLabel(questionnaire.powerOfAttorneyExists, RADIO_NO_YES);
  resultDictionary[
    "Kontaktdaten der zu betreuenden Person - Patientenverfügung vorhanden"
  ] = findElementLabel(questionnaire.patientDecreeExists, RADIO_NO_YES);

  resultDictionary[
    "Kontaktdaten der zu betreuenden Person - Wo werden diese aufbewahrt?"
  ] = questionnaire.locationOfDocuments;

  //
  // DementiaPage
  //
  // Demenzbetreuung
    resultDictionary["Demenzbetreuung - Besteht eine demenzielle Erkrankung?"] =
    findElementLabel(questionnaire.questionnaireData.dementia, RADIO_NO_YES);

    resultDictionary[
      "Demenzbetreuung - Um welchen Typ der Demenz handelt es sich?"
    ] = findElementLabel(
      questionnaire.dementiaData?.type,
      SELECT_DEMENTIA_TYPES
    );

    resultDictionary["Demenzbetreuung - Demenz wurde ärztlich diagnostiziert"] =
      findElementLabel(
        questionnaire.dementiaData?.diagnosedByProfessional,
        RADIO_NO_YES
      );
    resultDictionary["Demenzbetreuung - Aktuelle Therapien und Medikation"] =
      questionnaire.dementiaData?.currentTherapy;

    // Beurteilung und Bewertung der Demenz
    // Ist die Fähigkeit beeinträchtigt?
    resultDictionary[
      "Demenzbetreuung - Erkennen von Personen aus dem näheren Umfeld"
    ] = findElementLabel(
      questionnaire.dementiaData.restrictions?.recognizingPeople,
      RADIO_DEMENTIA_RESTRICTIONS
    );
    resultDictionary[
      "Demenzbetreuung - Steuern von mehrschrittigen Alltagshandlungen"
    ] = findElementLabel(
      questionnaire.dementiaData.restrictions?.controlOfMundaneTasks,
      RADIO_DEMENTIA_RESTRICTIONS
    );
    resultDictionary[
      "Demenzbetreuung - Treffen von adäquaten Entscheidungen im Alltag"
    ] = findElementLabel(
      questionnaire.dementiaData.restrictions?.mundaneDecisionMaking,
      RADIO_DEMENTIA_RESTRICTIONS
    );
    resultDictionary[
      "Demenzbetreuung - Verstehen von Sachverhalten und Informationen"
    ] = findElementLabel(
      questionnaire.dementiaData.restrictions?.comprehension,
      RADIO_DEMENTIA_RESTRICTIONS
    );
    resultDictionary["Demenzbetreuung - Erkennen von Risiken und Gefahren"] =
      findElementLabel(
        questionnaire.dementiaData.restrictions?.riskAssessment,
        RADIO_DEMENTIA_RESTRICTIONS
      );
    resultDictionary[
      "Demenzbetreuung - Mitteilen von elementaren Bedürfnissen"
    ] = findElementLabel(
      questionnaire.dementiaData.restrictions?.communicatingElementaryNeeds,
      RADIO_DEMENTIA_RESTRICTIONS
    );
    resultDictionary[
      "Demenzbetreuung - Verstehen und Umsetzen von Aufforderungen"
    ] = findElementLabel(
      questionnaire.dementiaData.restrictions?.understandingAndFollowingRequests,
      RADIO_DEMENTIA_RESTRICTIONS
    );
    resultDictionary["Demenzbetreuung - Beteiligen an einem Gespräch"] =
      findElementLabel(
        questionnaire.dementiaData.restrictions?.conversationParticipation,
        RADIO_DEMENTIA_RESTRICTIONS
      );

    //Verhaltensweisen und psychische Problemlagen
    resultDictionary[
      "Verhaltensweisen und psychische Problemlagen - Motorische Verhaltensauffälligkeiten"
    ] = findElementLabel(
      questionnaire.dementiaData.problems?.motorProblems,
      RADIO_DEMENTIA_RESTRICTIONS
    );

    resultDictionary[
      "Verhaltensweisen und psychische Problemlagen - Nächtliche Unruhe"
    ] = findElementLabel(
      questionnaire.dementiaData.problems?.nightlynrest,
      RADIO_DEMENTIA_RESTRICTIONS
    );

    resultDictionary[
      "Verhaltensweisen und psychische Problemlagen - Verstecken, sammeln, verlegen von Gegenständen und/oder Geld"
    ] = findElementLabel(
      questionnaire.dementiaData.problems
        .hidingCollectingMisplacingObjectsOrMoney,
      RADIO_DEMENTIA_RESTRICTIONS
    );
    resultDictionary[
      "Verhaltensweisen und psychische Problemlagen - Beschädigen von Gegenständen"
    ] = findElementLabel(
      questionnaire.dementiaData.problems?.damagingObjects,
      RADIO_DEMENTIA_RESTRICTIONS
    );
    resultDictionary[
      "Verhaltensweisen und psychische Problemlagen - Körperlich aggressives Verhalten - schlagen (auch sich selbst), beißen, kratzen, kneifen"
    ] = findElementLabel(
      questionnaire.dementiaData.problems?.physicallyAggressiveBehaviour,
      RADIO_DEMENTIA_RESTRICTIONS
    );
    resultDictionary[
      "Verhaltensweisen und psychische Problemlagen - Verbale Aggression"
    ] = findElementLabel(
      questionnaire.dementiaData.problems?.verballyAggressiveBehaviour,
      RADIO_DEMENTIA_RESTRICTIONS
    );
    resultDictionary[
      "Verhaltensweisen und psychische Problemlagen - Andere pflegerelevante auffällige Lautäußerungen"
    ] = findElementLabel(
      questionnaire.dementiaData.problems?.otherRelevantVocalizations,
      RADIO_DEMENTIA_RESTRICTIONS
    );
    resultDictionary[
      "Verhaltensweisen und psychische Problemlagen - Abwehr pflegerischer und anderer unterstützender Maßnahmen"
    ] = findElementLabel(
      questionnaire.dementiaData.problems?.defenseOfSupportiveMeasures,
      RADIO_DEMENTIA_RESTRICTIONS
    );
    resultDictionary["Verhaltensweisen und psychische Problemlagen - Ängste"] =
      findElementLabel(
        questionnaire.dementiaData.problems?.fears,
        RADIO_DEMENTIA_RESTRICTIONS
      );
    resultDictionary[
      "Verhaltensweisen und psychische Problemlagen - Antriebslosigkeit"
    ] = findElementLabel(
      questionnaire.dementiaData.problems?.listlessness,
      RADIO_DEMENTIA_RESTRICTIONS
    );
    resultDictionary[
      "Verhaltensweisen und psychische Problemlagen - Sozial inadäquate Verhaltensweisen"
    ] = findElementLabel(
      questionnaire.dementiaData.problems?.sociallyInadequateBehaviour,
      RADIO_DEMENTIA_RESTRICTIONS
    );
    resultDictionary[
      "Verhaltensweisen und psychische Problemlagen - Suchterkrankungen (Medikamente, Alkohol etc.)"
    ] = findElementLabel(
      questionnaire.dementiaData.problems?.addictions,
      RADIO_DEMENTIA_RESTRICTIONS
    );
    resultDictionary[
      "Verhaltensweisen und psychische Problemlagen - Sexuelle körperliche Annäherungsversuche"
    ] = findElementLabel(
      questionnaire.dementiaData.problems?.sexualPhysicalOvertures,
      RADIO_DEMENTIA_RESTRICTIONS
    );
    resultDictionary[
      "Verhaltensweisen und psychische Problemlagen - Unangemessenes An- und Ausziehen"
    ] = findElementLabel(
      questionnaire.dementiaData.problems?.inappropriateChangingOfClothes,
      RADIO_DEMENTIA_RESTRICTIONS
    );
    resultDictionary[
      "Verhaltensweisen und psychische Problemlagen - Unangemessenes An- und Ausziehen"
    ] = findElementLabel(
      questionnaire.dementiaData.problems?.inappropriateChangingOfClothes,
      RADIO_DEMENTIA_RESTRICTIONS
    );
    // Für Sicherheit sorgen
    resultDictionary[
      "Für Sicherheit sorgen - Hinlauftendenz (Weglauftendenz)"
    ] = findElementLabel(
      questionnaire.dementiaData.wanderingTendency,
      RADIO_YES_NO_UNCLEAR
    );
    resultDictionary[
      "Für Sicherheit sorgen - Sicherung des Hauses/der Wohnung"
    ] = findElementLabel(
      questionnaire.dementiaData.securityHouseApartment.value,
      RADIO_NO_YES
    );


    resultDictionary["Für Sicherheit sorgen - Sicherung des Hauses/der Wohnung - Wie?"] =
      questionnaire.dementiaData.securityHouseApartment.detail;

    resultDictionary["Für Sicherheit sorgen - Sicherung der Umgebung"] = findElementLabel(
      questionnaire.dementiaData.securityEnvironment.value,
      RADIO_NO_YES
    );

    resultDictionary["Für Sicherheit sorgen - Sicherung der Umgebung - Wie?"] =
      questionnaire.dementiaData.securityEnvironment.detail;

    // Zusätzliche Fragen
    resultDictionary["Zusätzliche Fragen - Gut anleitbar zur Durchführung der Körperpflege?"] =
      findElementLabel(
        questionnaire.dementiaData.goodToGuideToHygiene,
        RADIO_YES_NO_UNCLEAR
      );
    resultDictionary["Zusätzliche Fragen - Gut anleitbar bei der Bewältigung des Alltags?"] =
      findElementLabel(
        questionnaire.dementiaData.goodToGuideToEverydayLife,
        RADIO_YES_NO_UNCLEAR
      );
    resultDictionary["Zusätzliche Fragen - Ritual oder eine Medikation bei Unruhezuständen?"] =
      findElementLabel(
        questionnaire.dementiaData.ritualOrMedicationForRestlessness.value,
        RADIO_YES_NO_UNCLEAR
      );
    resultDictionary["Zusätzliche Fragen - Was passiert bei Ablehnung/ablehnendem Verhalten?"] =
      questionnaire.dementiaData?.behaviourWhenRejected;

    resultDictionary["Zusätzliche Fragen - Die betroffene Person reagiert vorwiegend auf den"] =
      questionnaire.dementiaData.listensTo.value === "nickname"
        ? `Spitznamen, ${questionnaire.dementiaData.listensTo.detail}`
        : findElementLabel(
            questionnaire.dementiaData.listensTo.value,
            RADIO_DEMENTIA_LISTENS_TO
          );

    resultDictionary[
      "Zusätzliche Fragen - Wichtige Hinweise zur Tagesstruktur und Kooperationsbereitschaft"
    ] = questionnaire.dementiaData.dailyStructureAndCooperationNotes;


  // DiagnosisPage
  // Hauptgrund für die Betreuungsbedürftigkeit
  resultDictionary[
    "Altersbedingter reduzierter Allgemeinzustand ohne Diagnose?"
  ] = findElementLabel(
    questionnaire.questionnaireData.mainReasonOld,
    RADIO_NO_YES
  );
  resultDictionary["Altersbedingter reduzierter Allgemeinzustand ohne Diagnose? - Andere Gründe / Details"] =
    questionnaire.questionnaireData.mainReasonForCare;
  // Weitere Diagnosen
  resultDictionary["Altersbedingter reduzierter Allgemeinzustand ohne Diagnose? - Amputationen"] =
    questionnaire.questionnaireData.amputations;


  
  resultDictionary["Hauptgrund für die Betreuungsbedürftigkeit? - Lähmungen"] = questionnaire.questionnaireData.paralyses;

  resultDictionary["Hauptgrund für die Betreuungsbedürftigkeit? - Bewegungsapparat"] =
  questionnaire.questionnaireData.musculoskeletal ? questionnaire.questionnaireData.musculoskeletal?.map((elem) => findElementLabel(elem, CHECK_MUSCULOSKELETAL))
      .join(" | ") : "";

  resultDictionary["Hauptgrund für die Betreuungsbedürftigkeit? - Neurologische Erkrankungen"] =
  questionnaire.questionnaireData.neurological ? questionnaire.questionnaireData?.neurological.value?.map((elem) => findElementLabel(elem, CHECK_NEUROLOGICAL))
      .join(" | ") : "";

  resultDictionary["Hauptgrund für die Betreuungsbedürftigkeit? - Herzkreislaufsystem"] =
  questionnaire.questionnaireData.cardiovascular ? questionnaire.questionnaireData.cardiovascular?.map((elem) => findElementLabel(elem, CHECK_CARDIOVASCULAR))
      .join(" | ") : "";

  resultDictionary["Hauptgrund für die Betreuungsbedürftigkeit? - Magen-Darm-Trakt"] =
  questionnaire.questionnaireData.gastroduodenal ? questionnaire.questionnaireData.gastroduodenal?.map((elem) => findElementLabel(elem, CHECK_GASTRODUODENAL))
      .join(" | "): "";

  resultDictionary["Hauptgrund für die Betreuungsbedürftigkeit? - Atemwege"] = questionnaire.questionnaireData.respiratory?.map((elem) => findElementLabel(elem, CHECK_RESPIRATORY))
    .join(" | ");

  resultDictionary["Hauptgrund für die Betreuungsbedürftigkeit? - Aktuelle Probleme"] =
  questionnaire.questionnaireData.currentProblems ? questionnaire.questionnaireData.currentProblems?.map((elem) => findElementLabel(elem, CHECK_CURRENT_PROBLEMS))
      .join(" | "): "";

  resultDictionary["Hauptgrund für die Betreuungsbedürftigkeit? - Sonstige"] = 
  questionnaire?.questionnaireData.otherIlnesses?.map((elem) => findElementLabel(elem, CHECK_OTHER_ILLNESSES))
    .join(" | ");

  resultDictionary[
    "Hauptgrund für die Betreuungsbedürftigkeit? - Seit wann besteht die Krankheit, ist diese konstant oder verändert sich der Zustand?"
  ] = questionnaire.questionnaireData?.since;


  const contageous_value = findElementLabel(
    questionnaire.questionnaireData.contageous?.value,
    RADIO_NO_YES
  )

  resultDictionary["Hauptgrund für die Betreuungsbedürftigkeit? - ansteckende / übertragbare Krankheiten"] = 
  `${contageous_value}: ${questionnaire.questionnaireData.contageous?.detail}`;


  //
  // CareRequirementsPage
  //
  // Ruhen und Schlafen
  resultDictionary["Ruhen und Schlafen"] =
    questionnaire.questionnaireData.sleep.impairments?.map((elem) => findElementLabel(elem, CHECK_REST_AND_SLEEP))
      .join(" | ");

    resultDictionary["Ruhen und Schlafen - Welche Schlafmedikation"] =
      questionnaire.questionnaireData.sleep.medication.details;
  
  resultDictionary["Ruhen und Schlafen - Nachtruhe von ca."] =
    questionnaire.questionnaireData.sleep.from;

  resultDictionary["Ruhen und Schlafen - Nachtruhe bis ca."] =
    questionnaire.questionnaireData.sleep.to;

  resultDictionary["Ruhen und Schlafen - Steht häufig nachts auf?"] = findElementLabel(
    questionnaire.questionnaireData.sleep.interruptions,
    RADIO_SLEEP_INTERRUPTIONS
  );

  resultDictionary["Ruhen und Schlafen - Nächtliche Einsätze der Betreuungskraft sind notwendig"] =
    findElementLabel(
      questionnaire.questionnaireData.sleep.assistance.needed,
      RADIO_NO_YES
    );

  resultDictionary["Ruhen und Schlafen - Nächtliche Einsätze - Wie viele?"] =
    questionnaire.questionnaireData.sleep.assistance.frequency;
  resultDictionary["Ruhen und Schlafen - Nächtliche Einsätze - Gründe"] =
    questionnaire.questionnaireData.sleep.assistance.reason;

  resultDictionary["Ruhen und Schlafen - Hilfe bei der Lagerung im Bett benötigt"] =
    findElementLabel(
      questionnaire.questionnaireData.bedPositioning.needsHelp,
      RADIO_HELP_NEEDED
    );


  resultDictionary["Ruhen und Schlafen - Hilfe bei der Lagerung im Bett - Häufigkeit"] =
    questionnaire.questionnaireData.bedPositioning.helpFrequency;

  resultDictionary["Ruhen und Schlafen - Hilfe bei der Lagerung im Bett - Schwierigkeitsgrad"] =
    findElementLabel(
      questionnaire.questionnaireData.bedPositioning.dependence,
      RADIO_TRANSFER
    );
  

  resultDictionary["Ruhen und Schlafen - Hilfe beim Transfer benötigt"] = findElementLabel(
    questionnaire.questionnaireData.transfer.assistance.needed,
    RADIO_HELP_NEEDED
  );

  resultDictionary["Ruhen und Schlafen - Hilfe beim Transfer - Häufigkeit"] =
    questionnaire.questionnaireData.transfer.assistance.frequency;

  resultDictionary["Ruhen und Schlafen - Hilfe beim Transfer - Schwierigkeitsgrad"] =
    findElementLabel(
      questionnaire.questionnaireData.transfer.dependence,
      RADIO_TRANSFER
    );
  

  // Waschen und Kleiden
  resultDictionary["Waschen und Kleiden - Grundpflege"] = findElementLabel(
    questionnaire.questionnaireData.hygiene.basic,
    RADIO_DEPENDENCY_LEVELS_EXTENDED
  );

  resultDictionary["Waschen und Kleiden - Körperpflege erfolgt komplett im Bett"] =
    findElementLabel(
      questionnaire.questionnaireData.hygiene.onlyInBed,
      RADIO_NO_YES
    );
  

  resultDictionary["Waschen und Kleiden - Gewohnheiten / Probleme bei der Körperpflege"] =
    questionnaire.questionnaireData.hygiene.details;

  // Essen und Trinken
  resultDictionary["Essen und Trinken - Nahrung"] = questionnaire.questionnaireData.food.diet.value?.map((elem) => findElementLabel(elem, CHECK_FOOD_DIET))
    .join(" | ");

  resultDictionary["Essen und Trinken - Welche spezielle Diät/Diabetes?"] =
    questionnaire.questionnaireData.food.diet.special;

  resultDictionary["Essen und Trinken - Welche Nahrungsallergien?"] =
    questionnaire.questionnaireData.food.allergies.details;


  resultDictionary["Essen und Trinken - Welche Hilfe bei der Nahrungszubereitung?"] =
    questionnaire.questionnaireData.food.preparationHelp.details;

  resultDictionary["Essen und Trinken - Welche Hilfe bei der Nahrungsaufnahme?"] =
    questionnaire.questionnaireData.food.intakeHelp.details;

  resultDictionary["Essen und Trinken - Ernährungszustand"] = findElementLabel(
    questionnaire.questionnaireData.food.nutritionalStatus,
    RADIO_FOOD_NUTRITIONAL_STATUS
  );
  resultDictionary["Essen und Trinken - Trinkmenge"] = findElementLabel(
    questionnaire.questionnaireData.food.fluidIntake.value,
    RADIO_FOOD_FLUID_INTAKE
  );

  resultDictionary["Essen und Trinken - Max. Trinkmenge (in ml/Tag)"] =
    questionnaire.questionnaireData.food.fluidIntake.mlPerDay;

  resultDictionary["Essen und Trinken - Anmerkungen"] =
    questionnaire.questionnaireData.food.notes;

  // Ausscheiden
  resultDictionary["Ausscheiden - Toilettengang"] = findElementLabel(
    questionnaire.questionnaireData.excretion.toilet,
    RADIO_EXCRETION
  );
  resultDictionary["Ausscheiden - Weitere Angaben"] =
    questionnaire.questionnaireData.excretion.other?.map((elem) => findElementLabel(elem, CHECK_EXCRETION_OTHER))
      .join(" | ");
  resultDictionary["Ausscheiden - Urininkontinenz"] = findElementLabel(
    questionnaire.questionnaireData.excretion.incontinenceUrinary,
    RADIO_NO_SOMETIMES_ALWAYS
  );

  resultDictionary["Ausscheiden - Stuhlinkontinenz"] = findElementLabel(
    questionnaire.questionnaireData.excretion.incontinenceFecal,
    RADIO_NO_SOMETIMES_ALWAYS
  );

  resultDictionary["Ausscheiden - Inkontinenzversorgung"] = findElementLabel(
    questionnaire.questionnaireData.excretion.incontinenceCare.dependence,
    RADIO_INCONTINENCE_CARE
  );

  resultDictionary["Ausscheiden - Anmerkungen"] =
    questionnaire.questionnaireData.excretion.details;

  // Atmen und Sicherheit
  resultDictionary["Atmen und Sicherheit - Atmen"] =
    questionnaire.questionnaireData.breathing.impediments?.map((elem) => findElementLabel(elem, CHECK_BREATHING_IMPEDIMENTS))
      .join(" | ");
  resultDictionary["Atmen und Sicherheit - Es besteht ein Hausnotrufsystem"] = findElementLabel(
    questionnaire.questionnaireData.safety.homeEmergencyCallServiceExists,
    RADIO_NO_YES
  );

  // Bewusstsein und Orientierung
  resultDictionary["Bewusstsein und Orientierung - Bewusstseinzustand / Vigilanz"] = findElementLabel(
    questionnaire.questionnaireData.safety.stateOfConciousness,
    RADIO_SAFETY_CONSCIOUSNESS
  );
  resultDictionary["Bewusstsein und Orientierung - Medikamenteneinnahme"] = findElementLabel(
    questionnaire.questionnaireData.safety.medicationIntake,
    RADIO_SAFETY_MEDICATION_INTAKE
  );
  resultDictionary["Bewusstsein und Orientierung - Hinlauftendenz / Weglauftendenz"] = findElementLabel(
    questionnaire.questionnaireData.safety.wanderingTendency.value,
    RADIO_NO_YES
  );

  // Einschränkungen in der Orientierung
  resultDictionary["Einschränkungen in der Orientierung - Zeitlich"] =
    findElementLabel(
      questionnaire.questionnaireData.safety.orientationProblems.temporal,
      RADIO_NONE_SOMETIMES_ALWAYS
    );
  resultDictionary["Einschränkungen in der Orientierung - Örtlich"] =
    findElementLabel(
      questionnaire.questionnaireData.safety.orientationProblems.positional,
      RADIO_NONE_SOMETIMES_ALWAYS
    );
  resultDictionary["Einschränkungen in der Orientierung - zur Person"] =
    findElementLabel(
      questionnaire.questionnaireData.safety.orientationProblems.personal,
      RADIO_NONE_SOMETIMES_ALWAYS
    );
  resultDictionary["Einschränkungen in der Orientierung - situativ"] =
    findElementLabel(
      questionnaire.questionnaireData.safety.orientationProblems.situational,
      RADIO_NONE_SOMETIMES_ALWAYS
    );

  // Sich beschäftigen
  resultDictionary["Sich beschäftigen - Tagesgestaltung / Beschäftigung"] =
    findElementLabel(
      questionnaire.questionnaireData.occupation.dependence,
      RADIO_DEPENDENCY_LEVELS
    );

  // true = aufwecken |false = ausschlafen lassen
  resultDictionary["Sich beschäftigen - ausschlafen lassen"] = findElementLabel(
    questionnaire.questionnaireData.occupation.dailyRoutine.letSleep,
    RADIO_YES_NO
  );

  resultDictionary[
    "Sich beschäftigen - wecken um"
  ] = `${questionnaire.questionnaireData.occupation.dailyRoutine.wakeTime} Uhr`;


  // Tagesstruktur der zu betreuenden Person:
  resultDictionary["Tagesstruktur - morgens"] =
    questionnaire.questionnaireData.occupation.dailyRoutine.morning;
  resultDictionary["Tagesstruktur - vormittags"] =
    questionnaire.questionnaireData.occupation.dailyRoutine.forenoon;
  resultDictionary["Tagesstruktur - mittags"] =
    questionnaire.questionnaireData.occupation.dailyRoutine.noon;
  resultDictionary["Tagesstruktur - nachmittags"] =
    questionnaire.questionnaireData.occupation.dailyRoutine.afternoon;
  resultDictionary["Tagesstruktur - abends"] =
    questionnaire.questionnaireData.occupation.dailyRoutine.evening;
  resultDictionary["Tagesstruktur - Ehemalige berufliche Tätigkeit"] =
    questionnaire.questionnaireData.occupation.formerJob;
  resultDictionary["Tagesstruktur - Hobbys und Interessenbeschreibung"] =
    questionnaire.questionnaireData.occupation.hobbies;

  resultDictionary["Tagesstruktur - Aktuelle Therapien"] =
    questionnaire.questionnaireData.occupation.currentTherapies.value?.map((elem) => findElementLabel(elem, CHECK_CURRENT_THERAPIES))
      .join(" | ");
  resultDictionary["Tagesstruktur - Weitere Therapien"] =
    questionnaire.questionnaireData.occupation.currentTherapies.other;
  resultDictionary[
    "Tagesstruktur - Besuch von Tagespflege / Demenzcafé / Dialyse?"
  ] = findElementLabel(
    questionnaire.questionnaireData.occupation.outOfHomeAppointment.value,
    RADIO_NO_YES
  );

  resultDictionary["Tagesstruktur - Weiter Therapieren - Wie häufig?"] =
    questionnaire.questionnaireData.occupation.outOfHomeAppointment.frequency;
  

  // Kommunikation
  resultDictionary["Kommunikation - Sprachen"] =
    questionnaire.questionnaireData.communication.language.value?.map((elem) => findElementLabel(elem, RADIO_LANGUAGES))
      .join(" | ");

  resultDictionary["Kommunikation - Welche Fremdsprachen?"] =
    questionnaire.questionnaireData.communication.language.other;


  // Einschränkungen Kommunikation
  resultDictionary["Kommunikation - Einschränkungen Kommunikation - Sprache"] =
    questionnaire.questionnaireData.communication.impediments.speech?.map((elem) => findElementLabel(elem, CHECK_SPEECH_IMPEDIMENTS))
      .join(" | ");
  resultDictionary["Kommunikation - Einschränkungen Kommunikation - Hören"] = findElementLabel(
    questionnaire.questionnaireData.communication.impediments.hearing,
    CHECK_HEARING_IMPEDIMENTS
  );

  resultDictionary["Kommunikation - Einschränkungen Kommunikation - Sehen"] = findElementLabel(
    questionnaire.questionnaireData.communication.impediments.visual,
    CHECK_VISUAL_IMPEDIMENTS
  );

  // Sinn finden
  resultDictionary["Sinn finden - Mentale Verfassung"] =questionnaire.questionnaireData.mentalState?
    questionnaire.questionnaireData.mentalState.value?.map((elem) => findElementLabel(elem, CHECK_MENTAL_STATE))
      .join(" | ") : "";

  resultDictionary["Sinn finden - Sonstiges zur mentalen Verfassung?"] =
    questionnaire.questionnaireData.mentalState?.other;

  // Schmerzen und Schmerzbewältigung
  resultDictionary["Schmerzen und Schmerzbewältigung - Ruheschmerz"] =
    findElementLabel(
      questionnaire.questionnaireData.pain.level.resting,
      RADIO_ZERO_TO_TEN
    );
  resultDictionary["Schmerzen und Schmerzbewältigung - Belastungsschmerz"] =
    findElementLabel(
      questionnaire.questionnaireData.pain.level.strained,
      RADIO_ZERO_TO_TEN
    );


  resultDictionary["Schmerzen und Schmerzbewältigung - Belastungsschmerz - Grund"] =
    questionnaire.questionnaireData.pain.reason;
  resultDictionary["Schmerzen und Schmerzbewältigung - Belastungsschmerz - Behandlung"] =
    questionnaire.questionnaireData.pain.therapyVia;

  resultDictionary["Schmerzen und Schmerzbewältigung - Anmerkungen"] =
    questionnaire.questionnaireData.pain.notes;

  // Hilfsmittel
  resultDictionary["Hilfsmittel - Hilfsmittel in Verwendung"] =
    questionnaire.questionnaireData.aid.aidsInHousehold?.map((elem) => findElementLabel(elem, CHECK_AIDS_IN_HOUSEHOLD))
      .join(" | ");

    resultDictionary["Hilfsmittel - Details Orthese"] =
      questionnaire.questionnaireData.aid.orthotic.detail;

    resultDictionary["Hilfsmittel - Details Prothese"] =
      questionnaire.questionnaireData.aid.prosthesis.detail;

  resultDictionary["Hilfsmittel - Anmerkungen / Weitere"] =
    questionnaire.questionnaireData.aid.notes;

  //
  // HouseHoldPage
  //

  // Weitere an der Betreuung beteiligte Personen / Einrichtungen
  resultDictionary[
    "Weitere an der Betreuung beteiligte Personen / Einrichtungen - Wer lebt noch im Haushalt?"
  ] =
    questionnaire.questionnaireData.nurseRequestParameters.householdData.additionalPeople;
  resultDictionary[
    "Weitere an der Betreuung beteiligte Personen / Einrichtungen - Wer ist sonst an der Betreuung beteiligt?"
  ] =
    questionnaire.questionnaireData.nurseRequestParameters.householdData.tasksForContacts;
  resultDictionary[
    "Weitere an der Betreuung beteiligte Personen / Einrichtungen - Gewünschte hauswirtschaftliche Dienstleistungen - Hauswirtschaft"
  ] =
    questionnaire.questionnaireData.nurseRequestParameters.householdData.tasks.other?.map((elem) => findElementLabel(elem, CHECK_HOUSEHOLD_TASKS))
      .join(" | ");

  resultDictionary[
    "Weitere an der Betreuung beteiligte Personen / Einrichtungen - Gewünschte hauswirtschaftliche Dienstleistungen - Zahl zu versorgende Personen"
  ] = findElementLabel(
    questionnaire.questionnaireData.nurseRequestParameters.householdData
      .noOfPeople,
    RADIO_NO_OF_PEOPLE
  );

  resultDictionary[
    "Weitere an der Betreuung beteiligte Personen / Einrichtungen - Gewünschte hauswirtschaftliche Dienstleistungen - Weitere Wünsche"
  ] =
    questionnaire.questionnaireData.nurseRequestParameters.householdData.tasks.wishes?.map((elem) => findElementLabel(elem, CHECK_HOUSEHOLD_WISHES))
      .join(" | ");

  // Verfügbarkeit finanzieller Mittel und Arbeitsmittel
  resultDictionary[
    "Verfügbarkeit finanzieller Mittel und Arbeitsmittel - Sind alle im Haushalt notwendigen Arbeitsmittel (z.B. Staubsauger, Wischmop, Reinigungsmittel) vorhanden?"
  ] = findElementLabel(
    questionnaire.questionnaireData.nurseRequestParameters.householdData
      .cleaningSupplies,
    CHECK_HOUSEHOLD_CLEANING
  );
  resultDictionary[
    "Verfügbarkeit finanzieller Mittel und Arbeitsmittel - Angemessenes Haushaltsbudget für Mahlzeiten, gemeinsame Freizeit, Aktivitäten und Fortbewegen im Rahmen der Betreuung vorhanden?"
  ] = findElementLabel(
    questionnaire.questionnaireData.nurseRequestParameters.householdData.budget,
    CHECK_HOUSEHOLD_CLEANING
  );

  // Wohnsituation
  resultDictionary["Wohnsituation - Wohnlage und -art"] =
    questionnaire.questionnaireData.nurseRequestParameters.householdData.residentialSituation?.map((elem) => findElementLabel(elem, RADIO_RESIDENTIAL_SITUATION))
      .join(" | ");

  // Weitere an der Betreuung beteiligte Personen / Einrichtungen
  resultDictionary["Wohnsituation - Anzahl Zimmer?"] =
    questionnaire.questionnaireData.nurseRequestParameters.householdData.noOfRooms;
  resultDictionary["Wohnsituation - Wohnfläche (in m²)"] =
    questionnaire.questionnaireData.nurseRequestParameters.householdData.squareMeters;
  resultDictionary["Wohnsituation - Entfernung Einkaufsmöglichkeiten (in m)"] =
    questionnaire.questionnaireData.nurseRequestParameters.householdData.shoppingDistanceOnFoot;

  // Ausstattung des Wohnbereichs der Betreuungskraft
  resultDictionary[
    "Wohnsituation - Ausstattung des Wohnbereichs der Betreuungskraft - Raum-Art?"
  ] = findElementLabel(
    questionnaire.questionnaireData.nurseRequestParameters.householdData
      .nurseAccommodation.roomType,
    NURSE_ACCOMODATION
  );
  resultDictionary[
    "Wohnsituation - Ausstattung des Wohnbereichs der Betreuungskraft - Raunm-Reinigung?"
  ] = findElementLabel(
    questionnaire.questionnaireData.nurseRequestParameters.householdData
      .nurseAccommodation.room,
    CHECK_HOUSEHOLD_CLEANING
  );
  resultDictionary[
    "Wohnsituation - Ausstattung des Wohnbereichs der Betreuungskraft - Schrank, Bett, Bettzeug, Tisch sowie Sitzgelegenheit"
  ] = findElementLabel(
    questionnaire.questionnaireData.nurseRequestParameters.householdData
      .nurseAccommodation.furniture,
    CHECK_HOUSEHOLD_CLEANING
  );
  resultDictionary[
    "Wohnsituation - Ausstattung des Wohnbereichs der Betreuungskraft - Das Zimmer ist beheiz- und verschließbar sowie hygienisch einwandfrei und verfügt über ein Fenster mit Tageslichteinfall"
  ] = findElementLabel(
    questionnaire.questionnaireData.nurseRequestParameters.householdData
      .nurseAccommodation.roomLivingStandards,
    CHECK_HOUSEHOLD_CLEANING
  );
  resultDictionary[
    "Wohnsituation - Ausstattung des Wohnbereichs der Betreuungskraft - Küche?"
  ] = findElementLabel(
    questionnaire.questionnaireData.nurseRequestParameters.householdData
      .nurseAccommodation.kitchen,
    CHECK_HOUSEHOLD_CLEANING
  );

  resultDictionary[
    "Wohnsituation - Ausstattung des Wohnbereichs der Betreuungskraft - Badezimmer?"
  ] = findElementLabel(
    questionnaire.questionnaireData.nurseRequestParameters.householdData
      .nurseAccommodation.bathroomType,
    NURSE_ACCOMODATION
  );
  resultDictionary[
    "Wohnsituation - Ausstattung des Wohnbereichs der Betreuungskraft - Wasch- und Trockengelegenheit?"
  ] = findElementLabel(
    questionnaire.questionnaireData.nurseRequestParameters.householdData
      .nurseAccommodation.washing,
    CHECK_HOUSEHOLD_CLEANING
  );
  resultDictionary[
    "Wohnsituation - Ausstattung des Wohnbereichs der Betreuungskraft - WLAN-Zugang (uneingeschränkt und kostenfrei im Wohnraum)"
  ] = findElementLabel(
    questionnaire.questionnaireData.nurseRequestParameters.householdData
      .nurseAccommodation.wifi,
    CHECK_HOUSEHOLD_CLEANING
  );
  resultDictionary[
    "Wohnsituation - Ausstattung des Wohnbereichs der Betreuungskraft - Transportmöglichkeiten für Besorgungsfahrten und Freizeitaktivitäten (z.B. PKW, ÖPNV, planbare Mitfahrgelegenheiten, Fahrrad)"
  ] = findElementLabel(
    questionnaire.questionnaireData.nurseRequestParameters.householdData
      .nurseAccommodation.transport,
    CHECK_HOUSEHOLD_CLEANING
  );
  resultDictionary[
    "Wohnsituation - Ausstattung des Wohnbereichs der Betreuungskraft - Welche Transportmöglichkeiten gibt es für die Betreuungskraft"
  ] =
    questionnaire.questionnaireData.nurseRequestParameters.householdData.nurseAccommodation.transportDetails;
  resultDictionary[
    "Wohnsituation - Ausstattung des Wohnbereichs der Betreuungskraft - Weitere Ausstattung / sonstige Anmerkungen"
  ] =
    questionnaire.questionnaireData.nurseRequestParameters.householdData.nurseAccommodation.notes;
  //
  // NursePage
  //
  // Anforderungen an die Betreuungskraft
  resultDictionary["Anforderungen an die Betreuungskraft - Geschlecht"] =
    findElementLabel(
      questionnaire.questionnaireData.nurseRequestParameters.gender,
      RADIO_GENDER
    );
  resultDictionary["Anforderungen an die Betreuungskraft - Deutsche Sprache"] =
    questionnaire.questionnaireData.nurseRequestParameters.languageSkill.german?.map((elem) => findElementLabel(elem, CHECK_LANGUAGE_SKILL))
      .join(" | ");
  resultDictionary[
    "Anforderungen an die Betreuungskraft - Wünsche an die Betreuungskraft"
  ] =
    questionnaire.questionnaireData.nurseRequestParameters.nurseData.customerNotes;
  resultDictionary["Anforderungen an die Betreuungskraft - Alter"] =
    questionnaire.questionnaireData.nurseRequestParameters.ageRange.ranges?.map((elem) => findElementLabel(elem, CHECK_AGE))
      .join(" | ");
  resultDictionary["Anforderungen an die Betreuungskraft - Qualifikation"] =
    questionnaire.questionnaireData.nurseRequestParameters.nurseData.qualifications?.map((elem) => findElementLabel(elem, CHECK_QUALIFICATION))
      .join(" |  ");

  const headers = Object.keys(resultDictionary).join(delimiter);
  const values = Object.values(resultDictionary)
    .map(
      (value) => `"${value}"` // Encapsulate values in quotes to handle commas or newlines within values
    )
    .join(delimiter);
  const csvString = `${headers}\n${values}`;

  //console.log("csv => ", csvString);
  return csvString;
}
