import React, { useCallback } from 'react';
import * as PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import { useDispatch } from 'react-redux';
import { addDays, parseISO } from 'date-fns';
import Form from '../../form/components/Form';
import SubmitButton from '../../form/components/SubmitButton';
import { IdPropType, RefPropType } from '../../../proptypes/basic';
import { useInitialValues, useResourceSubmit } from '../../form/hooks';
import { CONTRACT_RESOURCE } from '../../api/resources';
import DateInput from '../../form/components/DateInput';
import ContactSelect from '../../form/components/specialized/ContactSelect';
import Checkbox from '../../form/components/Checkbox';
import RadioInput from '../../form/components/RadioInput';
import ConditionalField from '../../form/components/specialized/ConditionalField';
import { AGENCY_CONTRACT, PZH_CONTRACT } from '../contractTypes';
import { agencyContractDocumentSchema } from '../schema';
import DocumentTemplateSelect from '../../form/components/specialized/DocumentTemplateSelect';
import { createPdf, updateContract, useContract } from '../contractsSlice';
import FormPrefiller from '../../form/components/FormPrefiller';
import NewMailTemplateSelect from '../../mails/components/send/NewMailTemplateSelect';
import { CONTRACT_ENTITY } from '../../mailTemplates/entities';
import {
    CONTRACTING_PARTNER_CONTACT,
    useContactsByCustomerIdWithType,
    usePatientsByCustomerId,
} from '../../contacts/hooks';
import {
    PZH_CONTRACT_CANCELLATION_PERIOD,
    PZH_CONTRACT_FEE_TYPE_HALF,
    PZH_CONTRACT_FEE_HALF,
    PZH_CONTRACT_FEE_FULL,
} from '../config';
import { useMailDialog } from '../../mails/hooks';
import TextInput from '../../form/components/TextInput';
import { useUserEmployee } from '../../auth/hooks';
import { updateCustomer } from '../../customers/customersSlice';
import { useCustomerReminderDate } from '../../interestedCustomer/hooks';
import { isAfter } from 'date-fns';
import { indexContracts } from '../../contracts/contractsSlice';
import { indexNurseDeployments } from '../../nurseDeployments/nurseDeploymentsSlice';
import { showNurse } from '../../nurses/nursesSlice';
import { showAgency } from '../../agencies/agenciesSlice';
import ContractTypeSelect from '../../form/components/specialized/ContractTypeSelect';
import ContractTerminationTypeSelect from '../../form/components/specialized/ContractTerminationTypeSelect';
import { useAgency } from '../../agencies/agenciesSlice';
import { useContractsWithTypeByCustomerId } from '../../contracts/hooks';
import * as Yup from 'yup';
import { YupId } from '../../form/schema';
import { requestDocuSignEsignUrl } from '../hooks';

const useStyles = makeStyles((theme) => ({
    space: {
        marginTop: theme.spacing(4),
    },

    spaceLarge: {
        minHeight: 60,
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
    },
}));

const prepare = (values) => ({
    ...values,
    fee:
        values.contractType === PZH_CONTRACT_FEE_TYPE_HALF
            ? PZH_CONTRACT_FEE_HALF
            : PZH_CONTRACT_FEE_FULL,
    type: PZH_CONTRACT,
    sentVia: values.send && values.sentVia === 'post' ? ['post'] : [],
    rejectTill: addDays(parseISO(values.startAt), PZH_CONTRACT_CANCELLATION_PERIOD),
});

const ContractDocumentForm = ({ customerId, contractId, type, submitRef, onDone }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { employee: userEmployee } = useUserEmployee();

    const [contract] = useContract(contractId);
    const [patients] = usePatientsByCustomerId(customerId);
    const [contractingPartners] = useContactsByCustomerIdWithType(
        customerId,
        CONTRACTING_PARTNER_CONTACT
    );

    const [agency] = useAgency(contract?.agencyId);

    const setContract = (base, contract) => {
        if (
            contract.type === 'agency' &&
            (!base.agencyContract ||
                isAfter(new Date(contract.createdAt), new Date(base.agencyContract.createdAt)))
        ) {
            return {
                ...base,
                agencyContract: contract,
            };
        }

        if (
            contract.type === 'pzh' &&
            (!base.pzhContract ||
                isAfter(new Date(contract.createdAt), new Date(base.pzhContract.createdAt)))
        ) {
            return {
                ...base,
                pzhContract: contract,
            };
        }

        return base;
    };

    const prepareExtraEntities = useCallback(() => {
        return dispatch(indexContracts({ customer_id: customerId }))
            .then(({ data: contracts }) => {
                const { agencyContract, pzhContract } = contracts.reduce((carry, contract) => {
                    if (carry.agencyContract && carry.pzhContract) {
                        return setContract(carry, contract);
                    }

                    const preCarry = setContract(
                        { agencyContract: null, pzhContract: null },
                        carry
                    );

                    return setContract(preCarry, contract);
                });

                return { agencyContract, pzhContract };
            })
            .then(({ agencyContract, pzhContract }) => {
                return dispatch(
                    indexNurseDeployments({
                        customer_id: customerId,
                        agency_id: agencyContract?.agencyId,
                    })
                ).then(({ data: nurseDeployments }) => {
                    if (nurseDeployments.length !== 0) {
                        const currentDeployment = nurseDeployments.reduce((carry, deployment) => {
                            if (isAfter(new Date(deployment.careFrom), new Date(carry.careFrom))) {
                                return deployment;
                            }
                            return carry;
                        });

                        if (currentDeployment?.nurseId) {
                            return dispatch(showNurse({ id: currentDeployment?.nurseId })).then(
                                ({ data: nurse }) => {
                                    return {
                                        agencyContract,
                                        pzhContract,
                                        nurse,
                                    };
                                }
                            );
                        } else {
                            return {
                                agencyContract,
                                pzhContract,
                                undefined,
                            };
                        }
                    }

                    return { agencyContract, pzhContract };
                });
            })
            .then(({ agencyContract, pzhContract, nurse }) => {
                if (agencyContract?.agencyId || nurse?.agencyId) {
                    return dispatch(
                        showAgency({ id: agencyContract?.agencyId || nurse?.agencyId })
                    ).then(({ data: agency }) => {
                        return {
                            agency,
                            agencyContract,
                            pzhContract,
                            nurse,
                        };
                    });
                }

                return { agencyContract, pzhContract, nurse };
            });
            // .then(({ agencyContract, pzhContract, nurse }) => {
            //     if (agencyContract?.agencyId || nurse?.agencyId) {
            //             const data = { 'name': 'Alan-Lee Perkins', 'email':'alanlee.perkins@seskion.de', 'role': 'signer','document_name':'Beispiel PZH Vertrag!' , 'document_path': '/PzH/Kunden/33944/Verträge/PZH Vertrag_Kasper_2019-02-20/PZH Vertrag_Kasper_2019-02-20_02.pdf' };
            //             return dispatch(requestDocuSignEsignUrl({ data })
            //         ).then(({ data }) => {
            //             console.log("requestDocuSignEsignUrl=>",data);
            //         });
            //     }

            //     return { agencyContract, pzhContract, nurse };
            // });
    }, [customerId, dispatch]);

    const initialValues = useInitialValues(null, {
        contract: { startAt: null, sentVia: '' },
        template: {
            documentTemplate: '',
            contractingPartnerId: () =>
                contractingPartners.length === 1 ? contractingPartners[0].id : '',
            patientId: () => (patients.length === 1 ? patients[0].id : ''),
            patient2Id: '',

            price: '',
            arrivalDate: null,
        },
        send: false,
        mailTemplateId: '',
    });

    const handleCreateContract = useResourceSubmit(
        null,
        CONTRACT_RESOURCE,
        { customerId },
        { prepare }
    );

    const { openMailDialog, handleDynamic } = useMailDialog();
    const { addDaysToReminder } = useCustomerReminderDate(customerId);

    const handleSubmit = useCallback(
        async (
            { contract: contractValues, template: templateValues, mailTemplateId, send },
            formik
        ) => {
            // const data = { 'name': 'Alan-Lee Perkins', 'email':'alanlee.perkins@seskion.de', 'role': 'signer', 'document_name':'Beispiel PZH Vertrag!' , 'document_path': '/PzH/Kunden/33944/Verträge/PZH Vertrag_Kasper_2019-02-20/PZH Vertrag_Kasper_2019-02-20_02.pdf' };
            // const url = requestDocuSignEsignUrl({ data });
            // // const url = requestDocuSignEsignUrl();
            // console.log("url=>",url);
            // return;

            let _contract = null;
            let _contractId = contractId;
            if (!_contractId) {
                const result = await handleCreateContract({ ...contractValues, send }, formik);
                _contract = result.data;
                _contractId = _contract.id;
            }

            if (type === AGENCY_CONTRACT) {
                await dispatch(
                    updateContract({
                        id: _contractId,
                        terminationType: contractValues.terminationType,
                    })
                );
            }

            const document_data  = await dispatch(createPdf({ ...templateValues, id: _contractId }));
            console.log("document_data=>",document_data);

            if (onDone) {
                await onDone({ data: _contract });
            }

            const intint = new Date();
            const today = new Date(
                Date.UTC(intint.getFullYear(), intint.getMonth(), intint.getDate())
            );

            if (send && contractValues.sentVia === 'email') {
                await openMailDialog({
                    entity: CONTRACT_ENTITY,
                    entityId: customerId,
                    templateId: mailTemplateId,
                    extraEntities: prepareExtraEntities,
                    onSent: () => {
                        dispatch(
                            updateContract({
                                id: _contractId,
                                sentAt: today,
                                sentVia: ['email'],
                                rejectTill: addDays(today, PZH_CONTRACT_CANCELLATION_PERIOD),
                                followup: today,
                            })
                        );
                    },
                });
            }

            if (send && contractValues.sentVia === 'docuSign') {
                await openMailDialog({
                    entity: CONTRACT_ENTITY,
                    entityId: customerId,
                    templateId: mailTemplateId,
                    extraEntities: prepareExtraEntities,
                    onSent: () => {
                        dispatch(
                            updateContract({
                                id: _contractId,
                                sentAt: today,
                                sentVia: ['docuSign'],
                                rejectTill: addDays(today, PZH_CONTRACT_CANCELLATION_PERIOD),
                                followup: today,
                            })
                        );
                    },
                });
            }

            if (send && contractValues.sentVia === 'post') {
                if (type === PZH_CONTRACT) {
                    if (
                        userEmployee.locationId ===
                        parseInt(process.env.REACT_APP_LOCATION_HAMBURG_ID, 10)
                    ) {
                        dispatch(
                            updateContract({
                                id: _contractId,
                                sentVia: ['post'],
                            })
                        );
                    } else {
                        dispatch(
                            updateContract({
                                id: _contractId,
                                sentAt: today,
                                sentVia: ['post'],
                                rejectTill: addDays(today, PZH_CONTRACT_CANCELLATION_PERIOD),
                                followup: addDays(today, 2),
                            })
                        );
                        dispatch(
                            updateCustomer({ id: customerId, reminder: addDaysToReminder(2) })
                        );
                    }
                } else {
                    // AGENCY CONTRACT
                    dispatch(
                        updateContract({
                            id: _contractId,
                            sentVia: ['post'],
                        })
                    );
                }
            }
        },
        [
            handleCreateContract,
            onDone,
            dispatch,
            openMailDialog,
            customerId,
            contractId,
            type,
            addDaysToReminder,
            userEmployee,
            prepareExtraEntities,
        ]
    );


    const [contracts] = useContractsWithTypeByCustomerId(customerId, PZH_CONTRACT);

    function isStartDateInUse(new_start_date, contracts) {
        const other_contracts = contracts.filter(c=>c.id !== contractId && !c.endAt);
        console.log("oc=>",other_contracts);
        if (other_contracts == null) {
            return false;
        }
        return other_contracts.some(c => new Date(new_start_date) >= new Date(c.startAt)  && new Date(new_start_date) <= new Date(c.endAt) );
    }


    const pzhContractDocumentSchema = Yup.object().shape({
        contract: Yup.object().shape({
            startAt: Yup.date().nullable().max(new Date(),'Darf nicht in der Zukunft liegen').test(
                'is-date-in-use',
                'Vertrags-Start Datum bereits in Verwendung bei einem anderen Vertrag',
                function(value) {
                    //console.log(value);
                  return !isStartDateInUse(value, contracts);
                }
              ).required('Pflichtfeld'),
            sentVia: Yup.string(),
        }),
        template: Yup.object().shape({
            documentTemplate: YupId().required('Pflichtfeld'),
            contractingPartnerId: YupId().required('Pflichtfeld'),
            patientId: YupId().required('Pflichtfeld'),
            patient2Id: YupId(),
        }),
        send: Yup.bool().required(),
    });


    const handleOnChange = (newvalues, initvalues, setfieldvalue) => {
        //console.log('initvalues=>', initvalues);
        //console.log('newvalues=>', newvalues);
        //console.log('BLAAAAAA', agency);

        if (contract === undefined || contract?.type !== 'agency') return;
        if (agency === undefined) return;

        let dt = agency.documentTemplates.filter(
            (d) => d.id === newvalues?.template?.documentTemplate
        );

        if (dt && dt.length > 0) {
            if (dt[0].filename.includes('_v02')) {
                setfieldvalue('contract.terminationType', 'fourweeks');
            } else if (dt[0].filename.includes('_v01')) {
                setfieldvalue('contract.terminationType', 'twoweeks');
            }
        }
    };

    return (
        <Form
            initialValues={initialValues}
            onSubmit={handleSubmit}
            onChange={handleOnChange}
            subject={CONTRACT_RESOURCE}
            validationSchema={
                type === PZH_CONTRACT ? pzhContractDocumentSchema : agencyContractDocumentSchema
            }
            withoutFeedback
        >
            <FormPrefiller
                values={{
                    contract: { startAt: new Date(), sentVia: 'email' },
                    send: true,
                    template: { price: '500', arrivalDate: new Date() },
                }}
            />
            <Grid container spacing={2}>
                {!contractId && (
                    <Grid item xs={6}>
                        <DateInput
                            name="contract.startAt"
                            label="Vertragsbeginn"
                            maxDate={new Date()}
                            fullWidth
                        />
                    </Grid>
                )}
                {/* type !== AGENCY_CONTRACT && (
                    <Grid item xs={6}>
                        <DateInput name="template.arrivalDate" label="Anreisedatum" maxDate='D'  fullWidth />
                    </Grid>
                ) */}
                <Grid item xs={6}>
                    <DocumentTemplateSelect
                        name="template.documentTemplate"
                        label="Vorlage"
                        userLocation={type === PZH_CONTRACT}
                        agencyId={contract ? contract.agencyId : null}
                        fullWidth
                    />
                </Grid>
                {type === PZH_CONTRACT && (
                    <Grid item xs={3}>
                        <ContractTypeSelect
                            name="contract.contractType"
                            label="Vertragsart"
                            fullWidth
                        />
                    </Grid>
                )}

                {type === AGENCY_CONTRACT && (
                    <>
                        <Grid item xs={6}>
                            <TextInput
                                name="template.price"
                                label="Monatliche Vergütung"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <ContractTerminationTypeSelect
                                name="contract.terminationType"
                                label="Kündigungsfrist"
                                disabled
                                fullWidth
                            />
                        </Grid>
                    </>
                )}
                <Grid item xs={12} className={classes.space}>
                    <ContactSelect
                        name="template.contractingPartnerId"
                        label="Vertragspartner"
                        customerId={customerId}
                        contractingPartner
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    <ContactSelect
                        name="template.patientId"
                        label="Zu betreuende Person"
                        customerId={customerId}
                        patients
                        fullWidth
                    />
                </Grid>
                {patients && patients.length > 1 && (
                    <Grid item xs={12}>
                        <ContactSelect
                            name="template.patient2Id"
                            label="Zweite zu betreuende Person"
                            customerId={customerId}
                            patients
                            fullWidth
                        />
                    </Grid>
                )}

                <Grid item xs={4} className={classes.spaceLarge}>
                    <Box ml={1}>
                        <Checkbox name="send" label="Versenden" noTurbo />
                    </Box>
                </Grid>
                <Grid item xs={8} className={classes.spaceLarge}>
                    <ConditionalField conditionFieldName="send">
                        <RadioInput
                            name="contract.sentVia"
                            translation="Select.sentVia"
                            horizontal
                            fullWidth
                            noTurbo
                        />
                    </ConditionalField>
                    <ConditionalField conditionFieldName="contract.sentVia" condition="email">
                        <NewMailTemplateSelect
                            name="mailTemplateId"
                            label="Vorlage"
                            entity={CONTRACT_ENTITY}
                            entityId={customerId}
                            onDynamic={handleDynamic}
                            fullWidth
                        />
                    </ConditionalField>
                    <ConditionalField conditionFieldName="contract.sentVia" condition="docuSign">
                        <NewMailTemplateSelect
                            name="mailTemplateId"
                            label="Vorlage"
                            entity={CONTRACT_ENTITY}
                            entityId={customerId}
                            onDynamic={handleDynamic}
                            fullWidth
                        />
                    </ConditionalField>
                </Grid>
            </Grid>
            <SubmitButton container={submitRef} />
        </Form>
    );
};

ContractDocumentForm.propTypes = {
    customerId: IdPropType.isRequired,
    contractId: IdPropType,
    type: PropTypes.string.isRequired,
    submitRef: RefPropType,
    onDone: PropTypes.func.isRequired,
};

ContractDocumentForm.defaultProps = {
    contractId: null,
    submitRef: null,
};

export default ContractDocumentForm;
