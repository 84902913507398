import { useMemo, useState, useEffect} from 'react';
import { AGENCY_CONTRACT, PZH_CONTRACT } from './contractTypes';
import { useContractsByCustomerId } from './contractsSlice';
import axios from 'axios';

export const useContractsWithTypeByCustomerId = (customerId, type) => {
    const [contracts, { loading, initialized }] = useContractsByCustomerId(customerId);

    const forType = useMemo(
        () => (contracts ? contracts.filter((contract) => contract.type === type) : []),
        [contracts, type]
    );

    return [forType, { loading, initialized }];
};

export const usePzHContractByCustomerId = (customerId) => {
    const [[contract], { loading, initialized }] = useContractsWithTypeByCustomerId(
        customerId,
        PZH_CONTRACT
    );
    return [contract, { loading, initialized }];
};

export const useAgencyContractsByCustomerId = (customerId) =>
    useContractsWithTypeByCustomerId(customerId, AGENCY_CONTRACT);

const fetchInvoicesByContractId = async (contractId) => {
    const response = await axios.get(`/api/v2/contracts/${contractId}/invoices`);
    return response.data;
};

export const useInvoicesByContracts = (contracts) => {
    const [invoices, setInvoices] = useState({});

    useEffect(() => {
        const fetchInvoices = async () => {
            const invoicesByContract = {};
            await Promise.all(
                contracts.map(async (contract) => {
                    const contractInvoices = await fetchInvoicesByContractId(contract.id);
                    invoicesByContract[contract.id] = contractInvoices;
                })
            );
            setInvoices(invoicesByContract);
        };

        if (contracts.length > 0) {
            fetchInvoices();
        }
    }, [contracts]);

    return invoices;
};

